.svgTriangulobottonBg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 103%;
  background: var(--secondary-color);
  box-sizing: border-box;
  clip-path: polygon(50% 100%, 100% 80%, 100% 0, 0 0, 0 80%);
}

.svgTriangulotopBG::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--overlay-video-content);
  box-sizing: border-box;
  clip-path: polygon(50% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
}

.svgTriangulotopBgWhite::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #ffffffb1;
  clip-path: polygon(50% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
}

.svgTriangulotop {
  clip-path: polygon(50% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
}

.svgTriangulobotton {
  clip-path: polygon(50% 100%, 100% 80%, 100% 0, 0 0, 0 80%);
}

.svgParrallelogra {
  clip-path: polygon(0 26%, 100% 0, 100% 100%, 0 81%);
}

.svgParrallelograBg {
  clip-path: polygon(0 0, 100% 0%, 78% 99%, 0% 100%);
}

.svgParrallelograInverso {
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.svgHexagon {
  clip-path: polygon(50% 0, 100% 20%, 100% 100%, 50% 100%, 0 100%, 0 20%);

}

.svgHexagonInverso {
  clip-path: polygon(50% 0, 100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
}

.svgTrapezidBg {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);

}

.svgParrallelograHorizontal {
  clip-path: polygon(0 20%, 100% 0, 100% 70%, 0 100%);
}

.svgCuadroBG {
  clip-path: polygon(100% 18%, 100% 90%, 89% 100%, 0 100%, 0 18%);
}

.svgServicesImgIzq {
  clip-path: polygon(0 0, 100% 12%, 100% 86%, 0% 100%);
}

.svgServicesImgDer{
  clip-path: polygon(0 12%, 100% 0, 100% 100%, 0 86%);
}


@media screen and (max-width: 560px) {
  .svgTriangulobottonBg::before {
    height: 125vh;
    clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
  }

  .svgTriangulotop,
  .svgTriangulotopBG::before,
  .svgTriangulotopBgWhite::before {
    clip-path: polygon(50% 0%, 100% 5%, 100% 100%, 0 100%, 0 5%);
  }

  .svgParrallelograInverso {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  .svgTriangulobotton{
    clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
  }
  .svgCuadroBG {
    clip-path: polygon(100% 0, 100% 90%, 89% 100%, 0 100%, 0 0);

  }
}